.Table {
    border-spacing: 0px;
    width : 100%;
    background: #fff;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  }
  
  .Cell {
    border: 1px solid #f4f6f8;
    padding: 4px;
    text-align: left;
  }