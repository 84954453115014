.container {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  max-width: 700px;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.color-primary {
  color: #0093cc;
}

.card {
  border: 1px solid #ccc;

  margin: 0 auto;
  width: 100%;

  box-shadow: 15px 14px 92px -82px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 15px 14px 92px -82px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 14px 92px -82px rgba(0, 0, 0, 0.75);
}

.card-content {
  padding: 1rem;
}

.card-header {
  height: 3rem;
  padding: 2.2rem 1rem;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  place-items: center;
  color: white;
}

.card-header-blue {
  background-color: #0093cc;
}

.card-header-green {
  background-color: #00cc00;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}
